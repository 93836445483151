exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._4wOQrBrd3Gk0Llp0yLdno{min-width:fit-content}", ""]);

// exports
exports.locals = {
	"useFullWidth": "_4wOQrBrd3Gk0Llp0yLdno"
};