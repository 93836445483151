exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1wa_HL9Kh2IcEGjN5WCuSS table.messages thead,._1wa_HL9Kh2IcEGjN5WCuSS table.messages thead th{background-color:#eee;color:#333}.ulaiJVOf5sYaWcTS3PTXx #message-table-paginator-top{margin-bottom:12px}", ""]);

// exports
exports.locals = {
	"messageListTableHeader": "_1wa_HL9Kh2IcEGjN5WCuSS",
	"messageListPaginator": "ulaiJVOf5sYaWcTS3PTXx"
};