exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._28Bg_K3RMP8gDvupbrzcE0:hover{color:unset;text-decoration:underline;cursor:pointer}", ""]);

// exports
exports.locals = {
	"labelAsLink": "_28Bg_K3RMP8gDvupbrzcE0"
};