exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2gvksBsUyJr20QPpvxjFrN{word-break:break-word}", ""]);

// exports
exports.locals = {
	"fieldsRowField": "_2gvksBsUyJr20QPpvxjFrN"
};