exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "div.plotly-notifier{visibility:hidden}._2eH1uNgSIBGCBt1YKOM8Fm .popover-content{padding:0}", ""]);

// exports
exports.locals = {
	"customPopover": "_2eH1uNgSIBGCBt1YKOM8Fm"
};