exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1NV_4c-tLEbIrZSSdGqwt2{padding-left:0;margin-bottom:20px}._1NV_4c-tLEbIrZSSdGqwt2 ._1NV_4c-tLEbIrZSSdGqwt2{margin-bottom:0}", ""]);

// exports
exports.locals = {
	"list": "_1NV_4c-tLEbIrZSSdGqwt2"
};