exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".kBxfMgU-UK22A3gPEOXSr{overflow:auto;height:100%}._2fPgAu6b0nwZbuyuw6whVF{left:0}", ""]);

// exports
exports.locals = {
	"container": "kBxfMgU-UK22A3gPEOXSr",
	"leftAligned": "_2fPgAu6b0nwZbuyuw6whVF"
};