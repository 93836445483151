exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3PsK8MKk5yUjti0bk-LXkB{display:flex;justify-content:center;align-items:center;height:100%;width:100%;text-align:center;padding-bottom:10px}", ""]);

// exports
exports.locals = {
	"container": "_3PsK8MKk5yUjti0bk-LXkB"
};