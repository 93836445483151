exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3IHnJOcl6rEpQHik89PEUe{background-color:#eee;padding:10px;margin:5px;border-radius:6px}._11B3G3DC4MLUeJmBBOifpB{padding-bottom:5px;text-transform:uppercase}", ""]);

// exports
exports.locals = {
	"descriptionBox": "_3IHnJOcl6rEpQHik89PEUe",
	"description": "_11B3G3DC4MLUeJmBBOifpB"
};