exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1pWtN2smmslj18KMvMfpHi{min-width:fit-content;opacity:.5;z-index:999999}", ""]);

// exports
exports.locals = {
	"draggedElement": "_1pWtN2smmslj18KMvMfpHi"
};