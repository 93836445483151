exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._33fB1w7fUEfR1asqoSH6vj{color:#666}._33fB1w7fUEfR1asqoSH6vj dd{font-family:monospace}._33fB1w7fUEfR1asqoSH6vj dd:not(:last-child){border-bottom:1px solid #ececec}", ""]);

// exports
exports.locals = {
	"messageFields": "_33fB1w7fUEfR1asqoSH6vj"
};