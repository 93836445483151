exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2vV2V5f2ova7kACHZyYZQL{padding:2px 5px}", ""]);

// exports
exports.locals = {
	"valueComponent": "_2vV2V5f2ova7kACHZyYZQL"
};