exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1TP7o-Xj_Jnqi4d3D6QSTH .popover-content{padding:0}", ""]);

// exports
exports.locals = {
	"customPopover": "_1TP7o-Xj_Jnqi4d3D6QSTH"
};