exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1sQ_ocCNZDpwqTTiODnrGc{opacity:.3;background-color:#9e1f63;width:40px;height:40px;position:fixed;bottom:60px;right:20px;border-radius:5px;border:none}._1sQ_ocCNZDpwqTTiODnrGc.middle{right:35%}._1sQ_ocCNZDpwqTTiODnrGc :hover{opacity:1}._3K9Qe56HnCP5Anhr6UsKe9{color:#fff;position:absolute;top:50%;left:50%;margin-top:-9px;margin-left:-5px}", ""]);

// exports
exports.locals = {
	"scroll": "_1sQ_ocCNZDpwqTTiODnrGc",
	"arrowUp": "_3K9Qe56HnCP5Anhr6UsKe9"
};