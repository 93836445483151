exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1jixTh9BOoFPVcz782zWo0{border-right:1px solid #d1d1d1}._2_12_yr8W9ZfMzq9KLoKAc{margin-bottom:15px}._1kwmellfs9K1G8NmzTMvyy{padding:7px}", ""]);

// exports
exports.locals = {
	"subnavigation": "_1jixTh9BOoFPVcz782zWo0",
	"horizontal": "_2_12_yr8W9ZfMzq9KLoKAc",
	"horizontalPreviousNextButtons": "_1kwmellfs9K1G8NmzTMvyy"
};